import React from 'react';
import { createRoot } from 'react-dom/client';
import Toast, { ToastProps } from './Toast';
import { v4 as uuidv4 } from 'uuid';

const toastRoot = document.getElementById('toast-root');

window.toast = (data: ToastProps) => {
    // get unique id
    data.id = uuidv4();
    // create div
    const el = document.createElement('div');
    el.setAttribute('id', data.id);
    el.setAttribute('class', 'toast');
    if (toastRoot) toastRoot.appendChild(el);
    data.el = el;

    const root = createRoot(el); // createRoot(container!) if you use TypeScript
    root.render(
        <React.StrictMode>
            <Toast {...data} />{' '}
        </React.StrictMode>
    );
};
