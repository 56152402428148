import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import differ from 'deep-diff';

import { FieldsContext } from './context';
//import { TextEditor } from '_dash/components/TextEditor';
import { TextEditor } from 'modules/react-texteditor';
import SelectInput from 'modules/react-select-input';
import SelectInput1 from 'modules/react-select-input/SelectInput1';
import LocationAutocomplete from 'modules/location-autocomplete';
import MultipleCheckbox from './customTypes/MultipleCheckbox';
import MultipleUpload from './customTypes/MultipleUpload';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import FieldRadioRender from './FieldRadioRender';

export default class Field extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.name + Number(new Date()) + '_' + (props.index ? props.index : ''), // set a uniq id to use in label->htmlFor and input->id
        };
        this.fieldRef = React.createRef();
        this.containerRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    propDefaultValue = () => {
        let fieldValue;
        // put in context.values the props.defaultValue only if the initialValues don't define already
        if (this.context.values[this.props.name] === undefined && this.props.defaultValue !== undefined) {
            fieldValue = this.props.defaultValue;
        }
        // in developer mode the component mount 2 times, on unmount this field context value is deleted, so make sure to reinitialize
        if (this.context.values[this.props.name] === undefined && this.context.initialValues[this.props.name] !== undefined) {
            fieldValue = this.context.initialValues[this.props.name];
        }
        if (fieldValue !== undefined) {
            this.context.updateState((prevState) => ({
                ...prevState,
                values: {
                    ...prevState.values,
                    [this.props.name]: fieldValue,
                },
            }));
        }
    };

    componentDidMount() {
        if (this.props.schema) {
            const fieldSchema = { ...this.props.schema };

            if (this.fieldRef.current) {
                fieldSchema.ref = this.fieldRef.current;
            }
            if (this.containerRef.current) {
                // this.context.schema[this.props.name].containerRef = this.containerRef.current;
                fieldSchema.containerRef = this.containerRef.current;
            }

            this.context.updateState((prevState) => ({
                ...prevState,
                schema: {
                    ...prevState.schema,
                    [this.props.name]: fieldSchema,
                },
            }));
        }
        this.propDefaultValue();
        // alert me if by mistake use same name
        /*if(this.context.schema.hasOwnProperty(this.props.name))
			window.swal({type:"warning",text:'name="'+this.props.name + '" is already used in form'});
		else
			this.context.schema[this.props.name] = this.props.schema;*/
    }

    handleChange(e) {
        // this.context.handleChange({[this.props.name]:e.target.value.trim()});
        let val;
        switch (this.props.schema.type) {
            case 'file':
                // val = e.target !== undefined ? e.target.files[0] : e;
                if (e.target === undefined) {
                    val = e;
                } else {
                    if (e.target.multiple) {
                        val = e.target.files; // FileList
                    } else {
                        if (e.target.files[0].size > 0) {
                            val = e.target.files[0]; // File
                        } else {
                            window.swal({ type: 'warning', text: 'The file uploaded is empty!' });
                        }
                    }
                }
                break;
            case 'texteditor':
                {
                    //parse content editor to check if has text used by required rule
                    let tmp = document.createElement('DIV');
                    tmp.innerHTML = e;
                    if ((tmp.textContent || tmp.innerText).trim() === '') {
                        val = '';
                    } else {
                        val = e;
                    }
                }
                break;
            case 'checkbox':
            case 'switchOnOff':
                if (this.props.schema.typeValue === 'boolean') {
                    val = e.target.checked;
                } else {
                    val = e.target.checked ? 1 : 0;
                }
                break;
            case 'selectInput':
            case 'selectInputHooks':
                val = e.constructor === Object ? e.val : e; // if object, get .val else get the string/whatever value;
                break;
            case 'multipleCheckbox':
            case 'multipleUpload':
            case 'phone':
            case 'custom':
                val = e;
                break;
            case 'datePicker':
                // the format used in DB
                val = moment(e).format('YYYY-MM-DD');
                break;
            default:
                //removed trim() due to change in componentDidUpdate
                //val = e.target.value.trim();
                val = e.target.value;
                if (this.props.schema.typeValue === 'number') {
                    val = parseInt(val);
                    if (isNaN(val)) val = null;
                }
        }
        //used to format field value
        switch (this.props.schema.format) {
            case 'number':
                val = val.toString().replace(/,/g, ''); // remove comma if exist
                val = val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); //add comma separator
                // also update the visual dom
                this.fieldRef.current.value = val;
                break;
            default:
                break;
        }
        // parse value by schema rules
        if (this.props.schema.rules) {
            const { rules } = this.props.schema;
            for (var ruleName in rules) {
                switch (ruleName) {
                    case 'url':
                        val = val.trim();
                        if (!(val.substr(0, 8) === 'https://' || val.substr(0, 7) === 'http://')) {
                            val = 'http://' + val;
                        }
                        break;

                    default:
                        break;
                }
            }
        }

        //save in context the value
        this.context.handleChange(this.props.name, val); // handleChange(key,val)

        if (this.props.onChange) this.props.onChange(e);
    }

    componentWillUnmount() {
        const { name } = this.props;
        this.context.updateState((prevState) => {
            const values = { ...prevState.values };
            const schema = { ...prevState.schema };
            const errors = { ...prevState.errors };
            delete values[name];
            delete schema[name];
            delete errors[name];

            return { ...prevState, values, schema, errors };
        });
    }

    componentDidUpdate(prevProps) {
        this.propDefaultValue();
        const { name, schema } = this.props;
        // reset textarea after context.values is reset
        if (
            (schema.type === 'textarea' || schema.type === 'text') &&
            (this.context.values[name] === undefined || this.context.values[name] === '') &&
            this.fieldRef.current.value.length > 0
        ) {
            this.fieldRef.current.value = '';
        }
        // update ref value for visual effect for input, textarea
        // case when the value is updated from fieldContext.handleChange() or props.initialValues
        if (
            (schema.type === 'textarea' || schema.type === 'text') &&
            this.context.values[name] !== undefined &&
            this.context.values[name] !== '' &&
            this.fieldRef.current.value !== this.context.values[name]
        ) {
            this.fieldRef.current.value = this.context.values[name];
        }

        if (prevProps.schema.rules !== undefined && prevProps.schema.rules.customAsyncFn === undefined) {
            // fix error message when schema rules is updated
            let shouldUpdateRules = false;
            const rulesDiff = differ(Object.keys(prevProps.schema.rules), Object.keys(schema.rules)); // we do diff by keys ONLY
            if (rulesDiff === undefined) {
                // keys are the same
                for (const [key, item] of Object.entries(prevProps.schema.rules)) {
                    // we test against non-function values - we want to skip function values
                    if (typeof item !== 'function') {
                        if (differ(item, schema.rules[key]) !== undefined) {
                            // a non-function value differs
                            shouldUpdateRules = true;
                            break;
                        }
                    }
                }
            } else {
                shouldUpdateRules = true; // keys DO differ, irrespective of values
            }
            if (shouldUpdateRules) {
                // if (window.devMode) window.console.log('Field.componentDidUpdate-3', { name, schema }, rulesDiff); // @debug
                //update schema in context
                this.context.updateState((prevState) => ({
                    ...prevState,
                    schema: {
                        ...prevState.schema,
                        [name]: {
                            ...prevState.schema[name],
                            ...schema,
                        },
                    },
                }));
                //validate again if have error message
                if (this.context.errors[name] !== undefined && this.context.errors[name].length > 0) {
                    this.context.handleChange(name, this.context.values[name]);
                }
            }
        }
    }

    render() {
        const {
            // tag:Tag,
            schema,
            className,
            name,
            render,
            renderType,
            radioData,
            classFormGroup,
            ...props
        } = this.props;
        // don't process Field without name
        if (!name) return <h5>Field miss `name`</h5>;

        if (render) {
            //return render(label,id,errorMsg,onChange,); //render(label,value,onChange)
            let errorMsg = '';
            switch (typeof this.context.errors[name]) {
                case 'string':
                    if (this.context.errors[name].length > 0) {
                        errorMsg = this.context.errors[name];
                    }
                    break;
                case 'object':
                    errorMsg = this.context.errors[name];
                    break;
                default:
                    // ignore
                    break;
            }
            const data = {
                id: this.state.id,
                ref: this.fieldRef,
                onChange: this.handleChange,
                name: name,
                label: schema.label,
                value: this.context.values[name],
                errorClass: this.context.errors[name] ? 'is-invalid' : '',
                // errorMsg: this.context.errors[name] !== undefined && this.context.errors[name].length > 0 ? this.context.errors[name] : '',
                errorMsg: errorMsg,
            };
            return render(data);
        } else {
            const displayType = () => {
                const className = ClassNames(schema.type === 'file' ? 'inputFile' : 'form-control', { 'is-invalid': this.context.errors[name] });
                switch (schema.type) {
                    case 'text':
                    case 'textarea': {
                        const Tag = schema.type === 'textarea' ? 'textarea' : 'input';
                        // props.value = this.context.values[name];
                        return (
                            <>
                                <Tag
                                    ref={this.fieldRef}
                                    {...props}
                                    className={className}
                                    //placeholder={schema && schema.label?schema.label:''}
                                    defaultValue={this.context.values[name]}
                                    // value={this.context.values[name]}
                                    onFocus={() =>
                                        ['Untitled form', 'Untitled Question', 'Add Form Title (Public)'].some((val) => val === this.context.values[name])
                                            ? this.context.handleChange(name, '')
                                            : ''
                                    }
                                    onChange={this.handleChange}
                                />
                                {this.context.values[name] !== undefined && props.maxLength !== undefined && (
                                    <span className="d-block text-right maxLength-field">
                                        {this.context.values[name].length}/{props.maxLength}
                                    </span>
                                )}
                            </>
                        );
                    }
                    case 'hidden': {
                        return <input type="hidden" />;
                    }
                    case 'file': // the standard file display is for single file ONLY
                        let fileName = 'Upload file';
                        if (this.context.values[name] !== undefined) {
                            if (this.context.values[name].constructor.name === 'File') {
                                fileName = this.context.values[name].name;
                            } else if (this.context.values[name].constructor.name === 'String' && this.context.values[name].length > 0) {
                                fileName = (
                                    <a href={this.context.values[name]} target="_blank" rel="noopener noreferrer">
                                        View
                                    </a>
                                );
                            }
                        }
                        return (
                            <p className="icon-add inline">
                                <input
                                    ref={this.fieldRef}
                                    {...props}
                                    type="file"
                                    id={this.state.id} //used by label -> htmlFor
                                    className={className}
                                    onChange={this.handleChange}
                                />
                                <label className="label-inputFile" htmlFor={this.state.id}>
                                    <span className="icon">
                                        <i className="far fa-plus" />
                                    </span>
                                    <span className="txt">{fileName}</span>
                                </label>
                            </p>
                        );
                    case 'checkbox': {
                        let isChecked = false;
                        if (this.context.values[name] !== undefined) {
                            isChecked = this.context.values[name];
                        }
                        return (
                            <div className="form-check">
                                <input ref={this.fieldRef} type="checkbox" className="form-check-input" id={this.state.id} onChange={this.handleChange} checked={isChecked} />
                                <label className="form-check-label" htmlFor={this.state.id}>
                                    {schema.label}
                                </label>
                            </div>
                        );
                    }
                    case 'multipleCheckbox':
                        return (
                            <MultipleCheckbox
                                {...props}
                                options={radioData}
                                forwardRef={this.fieldRef}
                                className={ClassNames({
                                    'is-invalid': this.context.errors[name],
                                })}
                                onChange={this.handleChange}
                                value={this.context.values[name]}
                            />
                        );
                    case 'multipleUpload':
                        if (schema.rules) {
                            // deal with rules
                            if (schema.rules.fileExtensionAccept) {
                                props.fileExtensionAccept = schema.rules.fileExtensionAccept;
                            }
                            if (schema.rules.maxFilesCount) {
                                props.maxFilesCount = schema.rules.maxFilesCount;
                            }
                        }
                        return (
                            <MultipleUpload
                                {...props}
                                forwardRef={this.fieldRef}
                                className={ClassNames({
                                    'is-invalid': this.context.errors[name],
                                })}
                                onChange={this.handleChange}
                                value={this.context.values[name]}
                            />
                        );
                    case 'switchOnOff': {
                        let isChecked = false;
                        if (this.context.values[name] !== undefined) {
                            isChecked = this.context.values[name];
                        }
                        const inputTags = {
                            ref: this.fieldRef,
                            type: 'checkbox',
                            id: this.state.id,
                            className: 'onoffswitch-checkbox',
                            onChange: this.handleChange,
                            checked: isChecked,
                        };
                        if (props.disabled) inputTags.disabled = 'true';
                        return (
                            <div className="onoffswitch">
                                <input {...inputTags} />
                                <label className="onoffswitch-label" htmlFor={this.state.id}>
                                    <span className="onoffswitch-inner" />
                                    <span className="onoffswitch-switch" />
                                </label>
                            </div>
                        );
                    }
                    case 'radio':
                        return <FieldRadioRender id={this.state.id} value={this.context.values[name]} handleChange={this.handleChange} radioData={radioData} ref={this.fieldRef} />;
                    case 'select':
                        const value = this.context.values[name] === undefined || this.context.values[name] === null ? '' : this.context.values[name];
                        return <select {...props} ref={this.fieldRef} className={value === '' ? className + ' empty' : className} value={value} onChange={this.handleChange} />;
                    case 'selectInput':
                        return (
                            <SelectInput
                                {...props}
                                ref={this.fieldRef}
                                className={ClassNames({
                                    'is-invalid': this.context.errors[name],
                                })}
                                value={typeof this.context.values[name] == 'undefined' ? '' : this.context.values[name]} //is required so use empty string as default value
                                onChange={this.handleChange}
                            />
                        );
                    case 'selectInputHooks':
                        return (
                            <SelectInput1
                                {...props}
                                className={ClassNames({
                                    'is-invalid': this.context.errors[name],
                                })}
                                value={typeof this.context.values[name] == 'undefined' ? '' : this.context.values[name]} //is required so use empty string as default value
                                onChange={this.handleChange}
                            />
                        );
                    case 'texteditor':
                        return (
                            <TextEditor
                                {...props}
                                ref={this.fieldRef}
                                className={ClassNames({
                                    'is-invalid': this.context.errors[name],
                                })}
                                //placeholder={schema && schema.label?schema.label:''}
                                onChange={this.handleChange}
                                content={this.context.values[name]}
                            />
                        );
                    case 'datePicker':
                        let selected = new Date();
                        if (props.selected !== undefined) {
                            selected = moment(props.selected).toDate();
                        } else if (this.context.values[name]) {
                            selected = moment(this.context.values[name]).toDate();
                        }
                        if (props.noDefault && !this.context.values[name]) selected = undefined;
                        // added prop.disabledKeyboardNavigation due to css issue
                        return <DatePicker {...props} selected={selected} onChange={this.handleChange} disabledKeyboardNavigation />;
                    case 'phone':
                        return (
                            <PhoneInputWithCountrySelect
                                value={this.context.values[name]}
                                defaultCountry="IE"
                                ref={this.fieldRef}
                                placeholder={props.placeholder ? props.placeholder : 'Enter phone number'}
                                onChange={this.handleChange}
                            />
                        );
                    case 'custom': {
                        const data = {
                            id: this.state.id,
                            ref: this.fieldRef,
                            forwardedRef: this.fieldRef,
                            onChange: this.handleChange,
                            name: name,
                            label: schema.label,
                            value: this.context.values[name],
                            errorClass: this.context.errors[name] ? 'is-invalid' : '',
                        };
                        if (schema.rules && schema.rules.fileAccept) {
                            const accept = schema.rules.fileAccept.map((e) => e.ext.split(',').map((ext) => e.type + '/' + ext));
                            data.accept = accept.join(',');
                        }
                        return renderType(data);
                    }
                    case 'googleAutoComplete':
                        return (
                            <div style={{ position: 'relative' }}>
                                <input type="text" style={{ position: 'absolute', top: 0, left: 0, zIndex: -1, border: 'none' }} ref={this.fieldRef} />
                                <LocationAutocomplete
                                    {...props}
                                    key={this.state.id}
                                    onDropdownSelect={(address, placeId) => {
                                        if (address && placeId) {
                                            this.context.handleChange(name, address);
                                            if (this.props.schema.needPlaceId === undefined || this.props.schema.needPlaceId) this.context.handleChange('place_id', placeId);
                                        }
                                        if (props.onChange !== undefined) {
                                            props.onChange({ address, placeId });
                                        }
                                    }}
                                    googleAPIKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                                    defaultValue={this.context.values[name]}
                                />
                                {(this.props.schema.needPlaceId === undefined || this.props.schema.needPlaceId) && <Field name="place_id" schema={{ type: 'hidden' }} />}
                            </div>
                        );
                    default:
                        return <h5>unKnown field type</h5>;
                }
            };

            return (
                // <React.Fragment>
                <div className={classFormGroup + ' ' + className} ref={this.containerRef}>
                    {schema !== undefined && schema.label !== undefined && schema.type !== undefined && schema.type !== 'checkbox' && (
                        <label className="label-title">
                            {schema.label}
                            {schema.rules !== undefined && schema.rules.required !== undefined && schema.rules.required !== false && schema.rules.required !== 0 && (
                                <sup className="text-danger">*</sup>
                            )}
                        </label>
                    )}
                    {displayType()}
                    {this.context.errors[name] && (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                            {this.context.errors[name]}
                        </div>
                    )}
                    {this.props.type === 'password' &&
                        schema.hideInfoPasswordStrength === undefined &&
                        this.context.values[name] !== undefined &&
                        this.context.values[name].length > 0 && <InfoPasswordStrength val={this.context.values[name]} />}
                </div>
                // </React.Fragment>
            );
        }
    }
}

Field.propTypes = {
    //tag: PropTypes.string,
    name: PropTypes.string.isRequired, // will be used as a part of the main object returned
    schema: PropTypes.shape({
        type: PropTypes.string.isRequired, //text,file,checkbox,radio,textEditor...
        rules: PropTypes.object,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    className: PropTypes.string,
    classFormGroup: PropTypes.string, //allow to remove(ex: classFormGroup="") or change(ex: classFormGroup="anotherClass") the default one
    render: PropTypes.func,
    renderType: PropTypes.func,
    radioData: PropTypes.array,
    onChange: PropTypes.func,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string]),
    type: PropTypes.string, //used for password field
};
Field.defaultProps = {
    // tag: 'input', // can be changed with textarea or select if need
    classFormGroup: 'form-group',
    className: '',
};

Field.contextType = FieldsContext; //read: https://reactjs.org/docs/context.html#classcontexttype

function InfoPasswordStrength({ val }) {
    // const strongRegex = new RegExp('^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$', 'g');
    const mediumRegex = new RegExp('^(?=.{6,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$', 'g');

    if (isStrongPassword(val)) {
        return <p className="passstrength ok">Strong password!</p>;
    } else if (mediumRegex.test(val)) {
        return <p className="passstrength alert">Medium password!</p>;
    } else {
        return <p className="passstrength error">Weak password!</p>;
    }
}

export function isStrongPassword(val) {
    let isStrongPassword = true;
    // min length 8
    if (val.length < 8) isStrongPassword = false;
    // check special chars
    if (!/[`~!@#$%^&*()=_+-]/.test(val)) isStrongPassword = false;
    // check lower case
    if (!/[a-z]/.test(val)) isStrongPassword = false;
    // check upper case
    if (!/[A-Z]/.test(val)) isStrongPassword = false;
    // check number
    if (!/[0-9]/.test(val)) isStrongPassword = false;

    return isStrongPassword;
}
