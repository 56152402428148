export default function FieldRadioRender({ id, value, radioData, handleChange, ref }) {
    return radioData.map((e) => {
        const radioId = id + e.value;
        let checkedValue = value;
        if (typeof e.value === 'number') checkedValue = parseFloat(checkedValue);
        return (
            <div className="form-check" key={e.value}>
                {/* here the ref is not properly used, in this case should be an array of ref because is inside an array, to be updated later */}
                <input ref={ref} type="radio" className="form-check-input" id={radioId} onChange={handleChange} checked={checkedValue === e.value} {...e} />
                <label className="form-check-label" htmlFor={radioId}>
                    {e.label}
                </label>
            </div>
        );
    });
}
